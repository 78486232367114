// plugins/axios.js
import axios from 'axios'
import { useAuth } from '@/composables/useAuth'

function setupAxiosInterceptors() {
  const { accessToken, refreshAccessToken, logout } = useAuth()
  const axiosAuthInstance = axios.create({
    baseURL: process.env.VUE_APP_SERVER_AUTH_URL,
    timeout: 30000,
    headers: {
      "Content-Type": "application/json",
    },
  });
  // Request interceptor
  axiosAuthInstance.interceptors.request.use(
    (config) => {
      if (accessToken.value) {
        config.headers.Authorization = `Bearer ${accessToken.value}`
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  // Response interceptor
  axiosAuthInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config
      console.log('error:originalRequest', originalRequest);

      // If error is 401 and we haven't tried to refresh token yet
      if (error.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true

        try {
          // Try to refresh the token
          await refreshAccessToken()

          // Retry the original request with new token
          originalRequest.headers.Authorization = `Bearer ${accessToken.value}`
          return axios(originalRequest)
        } catch {
          // If refresh fails, log out
          await logout()
          return Promise.reject(error)
        }
      }

      return Promise.reject(error)
    }
  )
}
export default setupAxiosInterceptors 