import { ref } from "vue";
import _ from "lodash";
import { getCollection } from "@/services/collection.service";
import { getUserState } from "@/firebase";
import {
  getUserById,
  getUserPermissionsByRoles,
} from "@/services/user.service";

let serviceInstance = null;

function createService() {
  const userPermissions = ref({});

  async function getUserPermissions(rebuild) {
    if (!_.isEmpty(userPermissions.value) && !rebuild) return;

    const userState = await getUserState();
    // console.log("createService:=>userState", userState);

    if (userState.uid) {
      const users = await getUserById(userState?.uid);
      // console.log('createService:=>users', users);

      if (!users?.role) return;
      const {
        role: userRole = "out",
      } = users;

      const { permissions: rolePermissions } = await getUserPermissionsByRoles(
        userRole
      );
      userPermissions.value = rolePermissions.reduce((acc, curr) => {
        acc[curr] = true;
        return acc;
      }, {});
    }
    // console.log('createService:=>userPermissions', userPermissions.value);

    return userPermissions.value;
  }

  function checkHasPermission(permissions = []) {
    if (!userPermissions.value) return false;
    return permissions.some(
      (permission) => userPermissions.value[permission] ?? false
    );
  }

  return {
    getUserPermissions,
    checkHasPermission,
  };
}

function useService() {
  if (!serviceInstance) serviceInstance = createService();

  return serviceInstance;
}

function resetAuthService() {
  serviceInstance = null;
}

export {
  createService as createAuthService,
  useService as useAuthService,
  resetAuthService,
};
