import { computed } from "vue";
import store from "@/store";
import { useAuth } from '@/composables/useAuth'
const {
  isAuthenticated,
  login,
  logout,
  switchMerchant,
  merchants,
  merchantId,
  user,
  error
} = useAuth()
// console.log('useAuth:isAuthenticated', isAuthenticated.value);

const merchant = computed(() => store.getters.merchant);

export async function checkAuthMiddleware(to, from, next) {
  /* Auth */
  const requiresAuth = to.matched.some((r) => r.meta.requiresAuth);
  const requiresUnauth = to.matched.some((r) => r.meta.requiresUnauth);
  // const at = JSON.parse(sessionStorage.getItem("qbeek:accessToken"));
  // if (fromUnixTime(at.expiresAt) < Date.now()) {
  //   // localStorage.setItem('qbeek:authUser', JSON.stringify(auth));

  //   // const { merchants: [merchant], refreshToken } = auth;
  //   // const accessToken = await getAccessToken({
  //   //   refreshToken: refreshToken,
  //   //   merchantId: merchant.id,
  //   // });
  //   // sessionStorage.setItem('qbeek:accessToken', JSON.stringify(accessToken));
  // }

  // const isAuth = await checkTokenValid({ accessToken: sessionStorage.getItem("qbeek:accessToken") });

  // eslint-disable-next-line no-debugger
  // debugger;

  if (requiresAuth && !isAuthenticated.value) {
    const locale = localStorage.getItem("locale") || "ru";

    next({
      name: "login",
      params: { locale },
      replace: true,
    });

    return;
  } else if (requiresAuth && !merchant.value) {
    await store.dispatch("get_merchant");

    return next();
  } else if (requiresUnauth && isAuthenticated.value) {
    next();

    return;
  }

  store.commit("SET_MENUITEM", to.meta.menuItems);

  next();
}
