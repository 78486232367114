import { createRouter, createWebHistory } from "vue-router";

import { accessGuardMiddleware } from "./middleware/accessGuard.middleware";
import { fetchAuthDataMiddleware } from "./middleware/fetchAuthData.middleware";
import { loadLayoutMiddleware } from "./middleware/loadLayout.middleware";
import { checkAuthMiddleware } from "./middleware/checkAuth.middleware";
import { loyaltyProgramCheckMiddleware } from "./middleware/loyaltyProgramCheck.middleware";
import { defaultRedirectMiddleware } from "./middleware/defaultRedirect.middleware";
import { goToNewPointMiddlewar } from "./middleware/goToNewPoint.middleware";
import i18n from "@/i18n";

import auth from "./auth";
import catalog from "./catalog";
import crm from "./crm";
import dashboard from "./dashboard";
import errorAccess from "./error";
import faq from "./faq";
import loyalty from "./loyalty";
import points from "./points";
import staff from "./staff";
import support from "./support";
// import wallet from "./wallet";
import profile from "./profile";
import backOffice from "./backOffice";
import statistic from "./statistic";
import integrations from "./integrations";
// import bankLinks from "./banks";
// import feedback from "./feedback";

const routes = [
  {
    path: "/:locale",
    component: {
      template: "<router-view></router-view>",
    },
    beforeEnter: (to, from, next) => {
      const locale = to.params.locale;
      const supported_locales =
        process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(",");
      if (!supported_locales.includes(locale)) return next();

      if (i18n.global.locale !== locale) {
        i18n.global.locale = locale;
      }

      return next();
    },
    children: [
      ...auth,
      ...backOffice,
      ...crm,
      ...catalog,
      ...dashboard,
      ...faq,
      ...loyalty,
      ...statistic,
      ...support,
      ...staff,
      // ...wallet,
      ...points,
      ...profile,
      ...integrations,
      ...errorAccess,
      // ...bankLinks,
      // ...feedback,
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect() {
      return process.env.VUE_APP_I18N_LOCALE;
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BASE_URL),
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      const element = document.querySelector(to.hash);
      if (element) {
        return {
          selector: to.hash,
          behavior: "smooth", // Добавляет плавный скролл
        };
      }
    }
    return savedPosition || { x: 0, y: 0 }; // По умолчанию
  },
});
router.onError((error) => {
  console.error(`[Router Error]`, error);
});
router.beforeEach(checkAuthMiddleware);
router.beforeEach(fetchAuthDataMiddleware);
router.beforeEach(accessGuardMiddleware);
router.beforeEach(defaultRedirectMiddleware);
router.beforeEach(loadLayoutMiddleware); // Not Closed!!!!!
router.beforeEach(loyaltyProgramCheckMiddleware);
router.beforeEach(goToNewPointMiddlewar);
export default router;
