import { computed } from "vue";
import { getPathFromUri, hexToRgb } from "@/utils/helpers";
import store from "@/store";
import axiosInstance from "./axios.service";
import {
  collection,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "@/firebase";

const merchant = computed(() => store.getters.merchant);
const loyalties = {
  /* DISCOUNT  ====================================== */
  discount: {
    description: "discount",
    headerFields: [
      {
        key: "discount",
        label: "СКИДКА",
        value: "<*percent*>%",
      },
    ],
    auxiliaryFields: [
      {
        key: "status",
        label: "СТАТУС",
        value: "<*level*>",
      },
      {
        key: "toDiscount",
        label: `До скидки <*toNextPercent*>%`,
        value: "<*toNextEdge*>",
      },
    ],
  },
  /* BONUS ====================================== */
  bonus: {
    description: "bonus",
    headerFields: [
      {
        key: "bonus",
        label: "БАЛАНС",
        value: "<*currentBonus*>",
      },
    ],
    auxiliaryFields: [
      {
        key: "status",
        label: "СТАТУС",
        value: "<*level*>",
      },
      {
        key: "toDiscount",
        label: "До бонуса <*toNextBonusPercent*>%",
        value: "<*toNextBonusEdge*>",
      },
    ],
  },
  /* CLUBCARD  ====================================== */
  clubcard: {
    description: "clubcard",
    headerFields: [
      {
        key: "deposit",
        label: "ДЕПОЗИТ",
        value: "<*currentDeposit*>",
      },
    ],
    auxiliaryFields: [
      {
        key: "logoText",
        value: "<*logoText*>",
      },
      {
        key: "cardNumber",
        label: "НОМЕР КАРТЫ",
        value: "<*externalId*>",
      },
    ],
  },
  /* COUPON {START, END}  ====================================== */
  coupon_start: {
    description: "coupon_start",
    auxiliaryFields: [
      {
        key: "toGift",
        label: "<*signatureForAccumulation*>",
      },
      {
        key: "points",
        value: "<*currentPoint*>/<*activateОnValue*>",
      },
    ],
  },
  coupon_end: {
    description: "coupon_end",
    auxiliaryFields: [
      {
        key: "getGift",
        label: "<placeToFillCongratulations*>",
      },
    ],
  },
};

export const getLoyalties = async () => {
  const url = "/v1/qbeek/merchant/loyalty";
  try {
    const result = await axiosInstance.get(url);
    return result;
  } catch (error) {
    console.log("getLoyalties:fnFirebase.js ===>>> ", error);
  }
};
export const fnGetLoyalties = async () => {
  const url = "/v1/qbeek/merchant/loyalty/levels";
  try {
    const result = await axiosInstance.get(url);
    return result;
  } catch (error) {
    console.log("getLoyalties:fnFirebase.js ===>>> ", error);
  }
};

export const fnGetLoyaltiesType = async () => {
  const url = "/v1/qbeek/merchant/loyalty/levels-type";
  try {
    const result = await axiosInstance.get(url);
    if (result?.error) {
      return null;
    }
    return result;
  } catch (error) {
    console.log("getLoyalties:fnFirebase.js ===>>> ", error);
  }
};
export const getStatusLoyalties = async () => {
  const url = "/v1/qbeek/merchant/loyalty/status";
  try {
    const result = await axiosInstance.get(url);
    return result;
  } catch (error) {
    console.log("getStatusLoyalties", error);
  }
};
export const getStatusLoyaltiesV = async () => {
  const url = "/v1/qbeek/merchant/loyalty/status-check";
  try {
    const result = await axiosInstance.get(url);
    return result;
  } catch (error) {
    console.log("getStatusLoyalties", error);
  }
};
export const getLoyaltyAndType = async () => {
  const url = "/v1/qbeek/merchant/loyalty/loyalty-type";
  try {
    const result = await axiosInstance.get(url);
    return result;
  } catch (error) {
    console.log("getStatusLoyalties", error);
  }
};

export const getDataLoyalties = async (type) => {
  const url = `/v1/qbeek/merchant/loyalty/read/${type}`;
  try {
    const result = await axiosInstance.get(url);

    // for (const key in result) {
    //   if (
    //     key === "maxApplyBonus" &&
    //     typeof result[key]?.rate === "number" &&
    //     result[key]?.rate < 1
    //   ) {
    //     result[key].rate = Math.round(+result[key]?.rate * 100);
    //   }
    // }
    return result;
  } catch (error) {
    console.log("getDataLoyalties", error);
  }
};
export const deleteLoyalty = async () => {
  const url = "/v1/qbeek/merchant/loyalty";
  try {
    const result = await axiosInstance.delete(url);
    return result;
  } catch (error) {
    console.error(error.message);
  }
};

/* ============================================================== */

export const saveDataLoyalties = async (target, type) => {
  const urlMerch = `/qbeek/merchant/read`;
  try {
    const merchant = await axiosInstance.get(urlMerch);
    const { merchantId } = merchant;
    const urlWallet = `/v1/qbeek/wallet/read`;
    const wallet = await axiosInstance.get(urlWallet);
    const mainSettings = {
      type: "storeCard",
      organizationName: wallet.logoText,
      logoText: wallet.logoText,
      images: {
        icon: getPathFromUri(wallet.logo),
        logo: getPathFromUri(wallet.logo),
        strip: getPathFromUri(wallet.image),
        iconURL: wallet.logo || "",
        logoURL: wallet.logo || "",
        stripURL: wallet.image || "",
      },
      foregroundColor: hexToRgb(wallet?.selectedPreset.colors["relief-6"]),
      labelColor: hexToRgb(wallet?.selectedPreset.colors["relief-5"]),
      backgroundColor: hexToRgb(wallet?.selectedPreset.colors["relief-10"]),
      secondaryFields: [
        {
          key: "cardOwner",
          label: "Владелец карты",
          value: "<*fullname*>",
        },
      ],
      barcode: [
        {
          message: "<*uid*>:" + merchantId,
          format: "PKBarcodeFormatQR",
          messageEncoding: "iso-8859-1",
        },
      ],
    };
    const defaultObj = {
      active: [],
      bonus: {},
      discount: {},
    };
    const template = JSON.stringify({
      ...mainSettings,
      ...loyalties[type],
    });

    const url = `/v1/qbeek/merchant/loyalty/update/${type}`;

    const newLoyalty = {
      ...defaultObj,
      active: [type],
      [type]: { ...target, template },
    };
    try {
      const response = await axiosInstance.post(url, newLoyalty);

      return response;
    } catch (error) {
      console.error(error.message);
    }
  } catch (error) {
    console.log(error);
  }
};

export const updateTemplate = async (wallet) => {
  const { loyaltiesId, merchantId, merchantName } = merchant.value;
  const urlActiveLoyalty = `/qbeek/loyalties/read`;
  try {
    const type = (await axiosInstance.get(urlActiveLoyalty))?.active?.toString();
    const mainSettings = {
      type: "storeCard",
      organizationName: wallet.logoText,
      logoText: wallet.logoText,
      images: {
        icon: getPathFromUri(wallet.logo),
        logo: getPathFromUri(wallet.logo),
        strip: getPathFromUri(wallet.image),
        iconURL: wallet.logo || "",
        logoURL: wallet.logo || "",
        stripURL: wallet.image || "",
      },
      foregroundColor: hexToRgb(wallet?.selectedPreset.colors["relief-6"]),
      labelColor: hexToRgb(wallet?.selectedPreset.colors["relief-5"]),
      backgroundColor: hexToRgb(wallet?.selectedPreset.colors["relief-10"]),
      secondaryFields: [
        {
          key: "cardOwner",
          label: "Владелец карты",
          value: "<*fullname*>",
        },
      ],
      barcode: [
        {
          message: "<*uid*>:" + merchantId,
          format: "PKBarcodeFormatQR",
          messageEncoding: "iso-8859-1",
        },
      ],
    };

    const template = JSON.stringify({ ...mainSettings, ...loyalties[type] });

    const url = `/v1/qbeek/loyalties/update`;
    try {
      const response = await axiosInstance.post(url, {
        active: [type],
        [type]: { template },
        merchantName,
      });
      return response;
    } catch (error) {
      console.error(error.message);
    }
  } catch (error) {
    console.log(error);
  }


};

/* ------------------------------------------- */
export const getUserCards = async (phone) => {
  const url = `/qbeek/operator/card-by-phone-btoa?phone=${btoa(phone)}`;
  // /qbeek/operator/card-by-phone-btoa

  try {
    const cards = await axiosInstance.get(url);
    // const temp = [];
    // cards.forEach((doc) => {
    //   if (doc.phone === phone) {
    //     temp.push(doc);
    //   }
    // });
    return cards;
  } catch (error) {
    console.log(error);
    console.log("getUserCards:error ===>>> ", error);
  }
};
export const getStatusMessengerIntegrations = async () => {
  const url = `/qbeek/merchants-integrations/read`;
  try {
    const response = await axiosInstance.get(url);
    const keys = Object.keys(response);

    return keys.includes("telegram") || keys.includes("whatsapp");
  } catch (error) {
    console.log("getStatusLoyalties", error);
  }
};

export const updateDeposit = async ({ userId = "A012", amount = 0 }) => { };
