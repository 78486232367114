import { useAuthService } from "@/modules/auth/auth.service";
import { PathComponentEnum } from "@/router/constants";
import i18n from "@/i18n";

export function accessGuardMiddleware(to, from, next) {
  const { checkHasPermission } = useAuthService();
  const { accessPermissions } = to.meta;
  // console.log('to.meta:', to.meta);
  // console.log('accessPermissions:', accessPermissions);
  // console.log('checkHasPermission:', checkHasPermission);

  if (!accessPermissions) {
    // console.log('accessGuardMiddleware: !accessPermissions');
    next();

    return;
  }

  if (checkHasPermission(accessPermissions)) {
    // console.log('accessGuardMiddleware: checkHasPermission(accessPermissions) = ', checkHasPermission(accessPermissions));
    // console.log('accessGuardMiddleware: accessPermissions', accessPermissions);

    next();

    return;
  }
  console.log('accessGuardMiddleware: checkHasPermission(accessPermissions) = false');

  next({
    name: PathComponentEnum.accessError,
    params: { locale: i18n.global.locale },
  });
}
