import axios from "axios";
import { useAuth } from "@/composables/useAuth";
// services/apiService.js
const { accessToken, refreshAccessToken, logout } = useAuth()
// import { getAuth } from "firebase/auth";

// const auth = getAuth();

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_SERVER_URL, //"http://13.229.219.131:7000",
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
  },
});

// Request interceptor
axiosInstance.interceptors.request.use(
  async (config) => {
    const at = localStorage.getItem("qbeek:accessToken");
    if (at && at.accessToken) {
      config.headers.Authorization = `Bearer ${at.accessToken}`;
    }
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  async (error) => {
    const originalRequest = error.config
    let errorMessage = "An error occurred"; // Сообщение по умолчанию
    if (error.response) {
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true

        try {
          // Try to refresh the token
          await refreshAccessToken()

          // Retry the original request with new token
          originalRequest.headers.Authorization = `Bearer ${accessToken.value}`
          return axios(originalRequest)
        } catch {
          // If refresh fails, log out
          await logout()
          return Promise.reject(error)
        }
      }
      // ======================================================
      switch (error.response.status) {
        case 404:
          errorMessage = "Error 404: Resource not found";
          break;
        case 400:
          errorMessage = "Error 400: Bad Request";
          break;
        // Можно добавить обработку других статусов
        default:
          errorMessage = "Unexpected error occurred";
      }
    } else {
      errorMessage = error.message || "Network error";
    }
    return Promise.reject(errorMessage);
  }
);

// Function to make a POST request
export const getMenuItems = async (data, headers = {}) => {
  const url = "/menu-items";
  headers["target"] = "test-pos";
  try {
    const response = await axiosInstance.post(url, data, { headers });
    console.log("response:getMenuItems", response);
    const result = response.items;
    return result;
  } catch (error) {
    console.error("Error posting data:", error);
    // throw error;
  }
};

// Export axios instance with pre-configured interceptors
export default axiosInstance;
